.breadcrumb {
    background: white;
    .breadcrumb__item-truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .breadcrumb_link {
        margin-left: 10px;
    }
    .element-breadcrumb .breadcrumb_link {
        text-decoration: none;
        color: #395B64;
        font-size: 14px;
        margin-right: 10px;
    }
    .element-breadcrumb::after {
        content: "/";
        width: 7px;
        height: 18px;
    }
    .element-breadcrumb_last {
        color: #DD5353;
        background: #ffffff;
        font-size: 14px;
    }
    .element-breadcrumb_last:not(:first-child) {
        padding-left: 10px;
    }
    .element-breadcrumb {
        background: #ffffff;
    }
    li .element-breadcrumb:first-child {
        margin: 0;
    }
    @media only screen and (max-width: 768px) {
        .active {
            display: none;
        }
    }
}

.breadcrumb__block{
    padding: 0;
    text-align: left;
    display: flex;
}





