@import "@scss/components/normalize.scss";
@import "@scss/components/_breadcrumb.scss";
@import "@scss/components/forFilters.scss";
@import "@scss/components/perPage.scss";
@import "@scss/components/header.scss";
@import "@scss/components/forMenuActive.scss";

.paragraph-text {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  color: #395B64;
  font-weight: 400;
}
.paragraph-text-semi-bold {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  color: #395B64;
  font-weight: 600;
}

.input-block {
  background: #C0D9E0;
  padding: 3rem 8rem 6rem 8rem;
  max-width: 100%;
  margin: 0;
}

.search-block {
  padding:0;
  margin:0;
}

.custom-input {
  height: 3rem;
}

.search-btn {
  background: #395B64;
  color: #FFFFFF;
  font-size: 20px;
  height: 2.65rem;
  width: 100%;
  font-weight: 600;
  border-radius: 0;
}

@media (max-width: 720px) {
  .paragraph-text-semi-bold,
  .paragraph-text,
  .search-btn {
    font-size: 14px;
  }
  .input-block {
    padding: 3rem 3rem 6rem 3rem;
  }
}

.ui-menu {
  background: white;
  width: 2.5rem;
  border: 1px solid;
}

.ui-menu-item {
  margin-top: 0.8rem;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #395B64;
  list-style-type: none;
}

ul {
  padding-left: 1rem !important;
}

.ui-menu-item:hover {
  background: #ddd;
}

.ui-autocomplete-input, .ui-autocomplete-input:focus {
  outline: none;
  box-shadow: none;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #395B64;
  border: none;
  border-radius: 0;
}

.main-block {
  width: 1320px;
  height: 689px;
  background-image: url('/images/daqar-main.webp');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: left;
}

.text-block {
  width: 770px;
}

.main-text-first, .main-text-second {
  font-size: 56px;
  font-family: "Poppins", sans-serif;
  color: #395B64;
  display: flex;
  flex-direction: column;
}

.main-text-second {
  margin-left: 170px;
}

.main-text-first {
  margin-left: 20px;
}

@media (max-width: 319px) {
  .main-block {
    background-image: url('/images/daqar-main-small.webp');
    width: 290px;
    height: 350px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .main-block {
    background-image: url('/images/daqar-main-small.webp');
    width: 400px;
    height: 373px;
  }

  .text-block {
    margin-bottom: 330px;
  }

  input::placeholder, .ui-autocomplete-input, .ui-autocomplete-input:focus  {
    font-size: 14px;
  }

  .ui-autocomplete-input {
    height: 2.6rem;
  }

  .main-text-first, .main-text-second {
    font-size: 1.5rem;
  }

  .main-text-second {
    margin-left: 3.5rem;
  }

  .main-text-first {
    margin-left: -0.8rem;
  }

  .ui-menu {
    background: white;
    width: 18rem !important;
    border: 1px solid;
  }

  .ui-menu-item {
    font-size: 16px;
  }

  .input-block {
    padding: 1rem 1rem 2rem 1rem;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .main-block {
    background-image: url('/images/daqar-main-small.webp');
    width: 480px;
    height: 400px;
  }

  .text-block {
    margin-bottom: 380px;
  }

  input::placeholder {
    font-size: 18px;
  }

  .main-text-first, .main-text-second {
    font-size: 32px;
  }

  .main-text-second {
    margin-left: 60px;
  }

  .main-text-first {
    margin-left: -10px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .main-block {
    background-image: url('/images/daqar-main-small.webp');
    width: 740px;
    height: 498px;
  }
  .main-text-first, .main-text-second {
    font-size: 30px;
  }
  .main-text-second {
    margin-left: 90px;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .main-block {
    width: 960px;
    height: 498px;
  }
  .main-text-first, .main-text-second {
    font-size: 38px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .main-block {
    width: 960px;
    height: 498px;
  }
  .main-text-first, .main-text-second {
    font-size: 34px;
  }
}


