.header {
  .header__logo {
    width: 196px;
    height: 50px;
  }
  .header__link {
    color: #395B64;
    text-decoration: none;
    font-size: 18px;
  }
  .header__item {
    align-items: start;
    margin-left: 40px;
  }
}

@media (max-width: 992px) {
  .header__item {
    align-items: start;
    margin-left:40px;
    margin-top: 20px;
  }
  .login-logo {
    display: none;
  }
}

@media (max-width: 1280px) {
  .login-logo {
    width: 500px;
  }
}