$mainColor: #395B64;
$secondColor: #C0D9E0;

.form-item {
  padding: 20px 20px 0;

  .form-item:first-child {
    padding: 30px 20px 0 20px;
  }
}

.filter-menu-block {
  width: 100%;
  height: auto;
  background: $secondColor;
  border-radius: 10px;
  padding: 0;

  .filter-menu-block__label {
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: $mainColor;
  }
  .filter-menu-block__reset {
    width: 58px;
    height: 38px;
    color: $secondColor;
    background: $mainColor;
    border-radius: 10px;
    margin-bottom: 19px;
  }
  .select2-container {
    max-width: 100% !important;
  }
  .select2-selection--multiple:before {
    content: "";
    position: absolute;
    right: 7px;
    top: 42%;
    border-top: 5px solid #888;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
  .select2-container .select2-search--inline .select2-search__field {
    font-size: 14px;
    margin-left: 10px;
    padding-bottom: 20px;
  }
  .select2-container .select2-selection--single .select2-selection__rendered {
    line-height: 1;
  }
  .select2-search .select2-search--inline {
    font-size: 14px;
  }
  .select2-custom .select2-selection {
    height: 30px;
    border-radius: 0;
  }
  .select2-selection__placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1px;
    color: $mainColor;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: $mainColor;
    padding: 1px 20px;
    color: #fff;
  }
  textarea::placeholder {
    color: $mainColor;
    font-family: 'Poppins', sans-serif;
  }
}

.filterBtn {
  width: 100px;
  height: 38px;
  color: $secondColor;
  background: $mainColor;
  border-radius: 10px;
  margin-bottom: 19px;
}

.select2-container--default {
  .select2-results__option--highlighted[aria-selected]:hover {
    background-color: #568795;
    color: $secondColor;
  }
  .select2-dropdown .select2-search__field:focus, .select2-search--inline .select2-search__field:focus {
    border: none;
  }
  .select2-container--focus .select2-selection--multiple, .select2-container--default .select2-search--dropdown .select2-search__field {
    border: none;
  }
}